import React from 'react';
import { Button, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';

const ProfileSection = ({
  title,
  displayedRecordCount,
  totalRecordCount,
  onViewAllClicked,
  hideViewAll,
  children,
}) => (
  <Stack spacing={2}>
    <Stack direction="row" alignItems="flex-end" justifyContent="space-between">
      <Stack>
        <Typography variant="h6" align="left">{title}</Typography>
        <Typography variant="subtitle2" align="left">
          {`Showing ${displayedRecordCount} of ${totalRecordCount}`}
        </Typography>
      </Stack>
      {
        !hideViewAll && <Button onClick={onViewAllClicked}>View All</Button>
      }
    </Stack>
    {children}
  </Stack>
);

ProfileSection.defaultProps = {
  hideViewAll: false,
  onViewAllClicked: null,
};

ProfileSection.propTypes = {
  title: PropTypes.string.isRequired,
  displayedRecordCount: PropTypes.number.isRequired,
  totalRecordCount: PropTypes.number.isRequired,
  onViewAllClicked: PropTypes.func,
  hideViewAll: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default ProfileSection;
