import React from 'react';
import {
  Card, Divider, Stack, Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import LinkedAddress from '../../../common/components/LinkedAddress';

const Section = ({ title, children, flexGrow }) => (
  <Stack
    alignItems="flex-start"
    spacing={1}
    sx={{ flexGrow, py: 3 }}
  >
    <Typography sx={{ fontWeight: 'bold' }}>{title}</Typography>
    {children}
  </Stack>
);

Section.defaultProps = {
  flexGrow: 1,
};

Section.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  flexGrow: PropTypes.number,
};

const LicenseInfo = ({ license }) => (
  <Stack spacing={1}>
    <Stack direction="row" alignItems="center" spacing={0.5}>
      <Typography
        variant="caption"
        sx={{
          flexShrink: 0,
          lineHeight: 1.5,
          display: 'inline-flex',
          alignItems: 'center',
          pt: '2px',
        }}
      >
        LICENSE #:
      </Typography>
      <Typography align="left" sx={{ fontSize: 14 }}>{license?.number || 'N/A'}</Typography>
    </Stack>
    <Stack direction="row" alignItems="center" spacing={0.5}>
      <Typography
        variant="caption"
        sx={{
          flexShrink: 0,
          lineHeight: 1.5,
          display: 'inline-flex',
          alignItems: 'center',
          pt: '2px',
        }}
      >
        STATE:
      </Typography>
      <Typography align="left" sx={{ fontSize: 14 }}>{license?.state || 'N/A'}</Typography>
    </Stack>
  </Stack>
);

LicenseInfo.defaultProps = {
  license: {},
};

LicenseInfo.propTypes = {
  license: PropTypes.shape({
    number: PropTypes.string,
    state: PropTypes.string,
  }),
};

const Licenses = ({ licenses }) => {
  if (!licenses || licenses.length === 0) {
    return <LicenseInfo />;
  }

  return licenses.map((item) => (
    <LicenseInfo key={item?.number} license={item} />
  ));
};

Licenses.defaultProps = {
  licenses: [],
};

Licenses.propTypes = {
  licenses: PropTypes.arrayOf(
    PropTypes.shape({
      number: PropTypes.string,
      state: PropTypes.string,
    }),
  ),
};

const OverviewSection = ({
  specialties, licenses, practiceAddress, mailingAddress,
}) => (
  <Card sx={{ flexGrow: 1, px: 3, py: 1 }}>
    <Stack
      direction="row"
      justifyContent="space-evenly"
      alignItems="flex-start"
      spacing={3}
    >
      <Section title="Declared Specialties" flexGrow={3}>
        <Typography align="left">{specialties}</Typography>
      </Section>
      <Divider orientation="vertical" flexItem />
      <Section title="License(s)" flexGrow={2}>
        <Stack direction="row" spacing={5}>
          <Licenses licenses={licenses} />
        </Stack>
      </Section>
      <Divider orientation="vertical" flexItem />
      <Section title="Primary Practice Address">
        <LinkedAddress address={practiceAddress} />
      </Section>
      <Divider orientation="vertical" flexItem />
      <Section title="Mailing Address">
        <LinkedAddress address={mailingAddress} />
      </Section>
    </Stack>
  </Card>
);

OverviewSection.defaultProps = {
  specialties: '',
  licenses: [],
  practiceAddress: '',
  mailingAddress: '',
};

OverviewSection.propTypes = {
  specialties: PropTypes.string,
  licenses: PropTypes.arrayOf(PropTypes.shape({
    number: PropTypes.string,
    state: PropTypes.string,
  })),
  practiceAddress: PropTypes.string,
  mailingAddress: PropTypes.string,
};

export default OverviewSection;
