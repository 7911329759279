import isValid from 'date-fns/isValid';

const NPI_PREFIX_SUM = 24;

const sumDigits = (num) => {
  let sum = 0;
  let input = num;

  while (input) {
    sum += input % 10;
    input = Math.floor(input / 10);
  }

  return sum;
};

const isPseudoNPI = (npi) => /^[Mm]\d{9}$/.test(npi);

const validateNpi = (npi) => {
  if (isPseudoNPI(npi)) return true;

  const npiRegex = /^\d{10}$/;

  if (!npiRegex.test(npi)) return false;

  const npiDigits = npi.split('').map((num) => parseInt(num, 10));
  const checkSumDigit = npiDigits.pop();
  let totalSum = NPI_PREFIX_SUM;

  npiDigits.reverse();

  npiDigits.forEach((digit, index) => {
    if ((index % 2) === 0) {
      totalSum += sumDigits(digit * 2);
    } else if ((index % 2) === 1) {
      totalSum += digit;
    }
  });

  const totalSumCeiling = Math.ceil(totalSum / 10) * 10;

  return totalSumCeiling - totalSum === checkSumDigit;
};

const isValidDate = (date) => !!date && isValid(date);

const isNonEmptyString = (s) => typeof s === 'string' && !!s;

const isNonEmptyArray = (a) => Array.isArray(a) && a.length > 0;

export {
  validateNpi, isValidDate, isNonEmptyString, isNonEmptyArray,
};
