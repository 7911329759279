import React from 'react';
import { Breadcrumbs, Typography } from '@mui/material';
import StyledLink from 'common/components/StyledLink';
import { useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';

const ProfileBreadcrumbs = ({ providerName }) => {
  const [searchParams] = useSearchParams();
  const reportId = searchParams.get('reportId');
  const reportTitle = searchParams.get('reportTitle');

  if (reportId && reportTitle) {
    return (
      <Breadcrumbs>
        <StyledLink to="/similarity" text="Provider Similarity" />
        <StyledLink to={`/similarity/${reportId}`} text={reportTitle} />
        <Typography align="left">{providerName}</Typography>
      </Breadcrumbs>
    );
  }

  return (
    <Breadcrumbs>
      <StyledLink to="/provider" text="Provider Search" />
      <Typography align="left">{providerName}</Typography>
    </Breadcrumbs>
  );
};

ProfileBreadcrumbs.defaultProps = {
  providerName: '',
};

ProfileBreadcrumbs.propTypes = {
  providerName: PropTypes.string,
};

export default ProfileBreadcrumbs;
