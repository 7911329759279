import baseApi from '../baseApi';

const STATUS_ENDPOINT = '/status';

export const statusApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getStatus: builder.query({
      query: () => `${STATUS_ENDPOINT}`,
      providesTags: ['Status'],
    }),
  }),
});

export const {
  useGetStatusQuery,
} = statusApi;
