import { Stack, Typography } from '@mui/material';
import React from 'react';
import { useLazySearchQuery } from '../../api/provider_search/providerSearchApi';
import SearchForm from './components/SearchForm';
import SearchResultsTable from './components/SearchResultsTable';

const ProviderSearch = () => {
  const [search, { isLoading, data }] = useLazySearchQuery();
  const onSearch = (searchTerms) => {
    search(searchTerms, true);
  };

  return (
    <Stack spacing={3}>
      <Typography variant="h4" align="left">Provider Search</Typography>
      <Stack direction="row" spacing={2}>
        <SearchForm onSearch={onSearch} />
        <SearchResultsTable isLoading={isLoading} rows={data || []} />
      </Stack>
    </Stack>
  );
};

export default ProviderSearch;
