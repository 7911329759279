import React from 'react';
import PropTypes from 'prop-types';
import {
  TableFooter,
  TableRow,
  Pagination,
  TableCell,
} from '@mui/material';
import { Box } from '@mui/system';

const TablePaginationFooter = ({
  resultSize, rowsPerPage, page, handleChangePage, columnSpan,
}) => {
  const pageCount = parseInt(((resultSize - 1) / rowsPerPage) + 1, 10);

  return (
    <TableFooter>
      <TableRow>
        <TableCell align="right" colSpan={columnSpan}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Pagination
              shape="rounded"
              color="primary"
              page={page}
              count={pageCount}
              siblingCount={2}
              boundaryCount={1}
              onChange={handleChangePage}
            />
          </Box>
        </TableCell>
      </TableRow>
    </TableFooter>
  );
};

TablePaginationFooter.propTypes = {
  resultSize: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  columnSpan: PropTypes.number.isRequired,
};

export default TablePaginationFooter;
