import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, FormControl, InputLabel, Paper, Select, Stack, TextField,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import states from '../../../common/constants/states';
import countries from '../../../common/constants/countries';

const SearchForm = ({ onSearch }) => {
  const [npi, setNpi] = useState(null);
  const [npiType, setNpiType] = useState(null);
  const [taxonomyDescription, setTaxonomyDescription] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [organizationalName, setOrganizationalName] = useState(null);
  const [city, setCity] = useState(null);
  const [state, setState] = useState(null);
  const [country, setCountry] = useState(null);
  const [postalCode, setPostalCode] = useState(null);
  const [addressType, setAddressType] = useState(null);

  const onSubmit = () => {
    onSearch({
      npi,
      npiType,
      taxonomyDescription,
      firstName,
      lastName,
      organizationalName,
      city,
      state,
      country,
      postalCode,
      addressType,
    });
  };

  return (
    <Paper sx={{ padding: 2, width: 350 }}>
      <Stack spacing={2}>
        <TextField
          label="NPI"
          variant="outlined"
          size="small"
          value={npi}
          onChange={(e) => setNpi(e.target.value)}
        />
        <FormControl fullWidth size="small">
          <InputLabel id="npi-type">NPI Type</InputLabel>
          <Select
            labelId="npi-type"
            value={npiType}
            label="NPI Type"
            onChange={(e) => setNpiType(e.target.value)}
            sx={{ textAlign: 'start' }}
          >
            <MenuItem value={null}>Any</MenuItem>
            <MenuItem value="NPI-1">Individual</MenuItem>
            <MenuItem value="NPI-2">Organization</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="Taxonomy Description"
          variant="outlined"
          size="small"
          value={taxonomyDescription}
          onChange={(e) => setTaxonomyDescription(e.target.value)}
        />
        <TextField
          label="First Name"
          variant="outlined"
          size="small"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <TextField
          label="Last Name"
          variant="outlined"
          size="small"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        <TextField
          label="Organizational Name"
          variant="outlined"
          size="small"
          value={organizationalName}
          onChange={(e) => setOrganizationalName(e.target.value)}
        />
        <TextField
          label="City"
          variant="outlined"
          size="small"
          value={city}
          onChange={(e) => setCity(e.target.value)}
        />
        <FormControl fullWidth size="small">
          <InputLabel id="state">State</InputLabel>
          <Select
            labelId="state"
            value={state}
            label="state"
            onChange={(e) => setState(e.target.value)}
            sx={{ textAlign: 'start' }}
          >
            <MenuItem value={null}>Any</MenuItem>
            {
              states.map(({ abbreviation, name }) => (
                <MenuItem key={abbreviation} value={abbreviation}>{name}</MenuItem>
              ))
            }
          </Select>
        </FormControl>
        <FormControl fullWidth size="small">
          <InputLabel id="country">Country</InputLabel>
          <Select
            labelId="country"
            value={country}
            label="Country"
            onChange={(e) => setCountry(e.target.value)}
            sx={{ textAlign: 'start' }}
          >
            <MenuItem value={null}>Any</MenuItem>
            {
              countries.map(({ name, code }) => (
                <MenuItem key={code} value={code}>{name}</MenuItem>
              ))
            }
          </Select>
        </FormControl>
        <TextField
          label="Postal Code"
          variant="outlined"
          size="small"
          value={postalCode}
          onChange={(e) => setPostalCode(e.target.value)}
        />
        <FormControl fullWidth size="small">
          <InputLabel id="address-type">Address Type</InputLabel>
          <Select
            labelId="address-type"
            value={addressType}
            label="Address Type"
            onChange={(e) => setAddressType(e.target.value)}
            sx={{ textAlign: 'start' }}
          >
            <MenuItem value={null}>Any</MenuItem>
            <MenuItem value="PR">Primary Location</MenuItem>
            <MenuItem value="SE">Secondary Location</MenuItem>
          </Select>
        </FormControl>
        <Button variant="contained" onClick={onSubmit}>Search</Button>
      </Stack>
    </Paper>
  );
};

SearchForm.propTypes = {
  onSearch: PropTypes.func.isRequired,
};

export default SearchForm;
