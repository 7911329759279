import { SORT_ORDER } from './tableConstants';

const getComparisonValues = (a, b, orderBy) => {
  if (b[orderBy] instanceof Date && a[orderBy] instanceof Date) {
    return { left: b[orderBy], right: a[orderBy] };
  }

  if (!Number.isNaN(Number(b[orderBy])) && !Number.isNaN(Number(a[orderBy]))) {
    return { left: Number(b[orderBy]), right: Number(a[orderBy]) };
  }

  return { left: b[orderBy], right: a[orderBy] };
};

const descendingComparator = (a, b, orderBy) => {
  const { left, right } = getComparisonValues(a, b, orderBy);

  if (left < right) {
    return -1;
  }
  if (left > right) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => (order === SORT_ORDER.descending
  ? (a, b) => descendingComparator(a, b, orderBy)
  : (a, b) => -descendingComparator(a, b, orderBy));

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

export { getComparator, stableSort };
