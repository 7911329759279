/* eslint-disable react/forbid-prop-types */
import * as React from 'react';
import PropTypes from 'prop-types';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/MoreVert';
import appColors from 'assets/colors/appColors';

const ActionMenu = ({ actions, rowData, onItemClicked }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleActionClick = (action) => {
    onItemClicked(action.id, rowData);
    handleClose();
  };

  return (
    <div>
      <IconButton onClick={handleClick}>
        <MenuIcon htmlColor={appColors.purple} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {actions.map((action) => (
          <MenuItem key={action.label} onClick={() => handleActionClick(action)}>
            {action.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

ActionMenu.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.any).isRequired,
  rowData: PropTypes.any.isRequired,
  onItemClicked: PropTypes.func.isRequired,
};

export default ActionMenu;
