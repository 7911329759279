/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { TableCell } from '@mui/material';
import PropTypes from 'prop-types';
import { ACTIONS } from './tableConstants';
import ActionMenu from './ActionMenu';

const TableRowCells = ({
  row, columns, onActionItemClicked, tableMetaData,
}) => columns.map((column) => {
  if (column.id === ACTIONS) {
    return (
      <TableCell key={ACTIONS} sx={{ borderLeft: '1px solid lightGray' }}>
        <ActionMenu
          key={`${ACTIONS}-menu`}
          actions={column.actions}
          rowData={row}
          onItemClicked={onActionItemClicked}
        />
      </TableCell>
    );
  }

  if (column.renderCell) {
    return (
      <TableCell key={column.id}>{column.renderCell(row, tableMetaData)}</TableCell>
    );
  }

  return <TableCell key={column.id} align="left">{row[column.id]}</TableCell>;
});

TableRowCells.defaultProps = {
  onActionItemClicked: null,
  tableMetaData: {},
};

TableRowCells.propTypes = {
  row: PropTypes.any.isRequired,
  columns: PropTypes.arrayOf(PropTypes.any).isRequired,
  onActionItemClicked: PropTypes.func,
  tableMetaData: PropTypes.any,
};

export default TableRowCells;
