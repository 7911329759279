import baseApi from '../baseApi';
import toProviderSearchResult from '../../pages/ProviderSearchV2/dataFormatters';

const PROVIDER_SEARCH_ENDPOINT = '/provider/search';

export const providerSearchApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    search: builder.query({
      query: ({
        npi,
        npiType,
        taxonomyDescription,
        firstName,
        lastName,
        organizationalName,
        city,
        state,
        country,
        postalCode,
        addressType,
      }) => `${PROVIDER_SEARCH_ENDPOINT}?number=${npi}&enumeration_type=${npiType}&taxonomy_description=${taxonomyDescription}&first_name=${firstName}&last_name=${lastName}&organization_name=${organizationalName}&city=${city}&state=${state}&country_code=${country}&postal_code=${postalCode}&address_purpose=${addressType}`,
      providesTags: 'ProviderSearchResult',
      transformResponse: (response) => toProviderSearchResult(response?.results),
    }),
  }),
});

export const {
  useLazySearchQuery,
} = providerSearchApi;
