import baseApi from '../baseApi';
import toProviderInfo from '../../pages/ProviderProfile/dataFormatters';

const PROVIDER_ENDPOINT = '/provider';
const DEFAULT_PROVIDER_SCHEMA_VERSION = '2.0.1';

export const providerApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getProvider: builder.query({
      query: ({
        npi,
        allRecordsSection = null,
        version = DEFAULT_PROVIDER_SCHEMA_VERSION,
      }) => `${PROVIDER_ENDPOINT}/${npi}?version=${version ?? DEFAULT_PROVIDER_SCHEMA_VERSION}${allRecordsSection ? `&all_records_section=${allRecordsSection}` : ''}`,
      providesTags: (_result, _err, { npi }) => [{ type: 'Provider', id: npi }],
      transformResponse: (response) => toProviderInfo(response),
    }),
  }),
});

export const {
  useGetProviderQuery,
} = providerApi;

export { DEFAULT_PROVIDER_SCHEMA_VERSION };
