import React from 'react';
import PropTypes from 'prop-types';
import { Box, Stack, Typography } from '@mui/material';
import MaterialTable from '../../../common/components/MaterialTable/MaterialTable';
import FullScreenDialog from '../../../common/components/FullScreenDialog';
import { useGetProviderQuery } from '../../../api/provider/providerApi';
import { DIAGNOSIS_SUMMARY_COLUMNS } from './DiagnosisSummaryTable';
import { PLACE_OF_SERVICE_COLUMNS } from './PlaceOfServiceTable';
import { PRESCRIPTION_SUMMARY_COLUMNS } from './PrescriptionSummaryTable';
import { PROCEDURE_SUMMARY_COLUMNS } from './ProcedureSummaryTable';
import LoadingIndicator from '../../../common/components/LoadingIndicator/LoadingIndicator';
import GenericError from '../../../common/components/GenericError';

export const DialogType = {
  Diagnosis: 'diagnosis',
  PlaceOfService: 'placeOfService',
  Prescription: 'prescription',
  Procedure: 'procedure',
};

const getConfig = (dialogType, providerInfo) => {
  switch (dialogType) {
    case DialogType.Diagnosis:
      return {
        title: 'Diagnoses',
        columns: DIAGNOSIS_SUMMARY_COLUMNS,
        rows: providerInfo?.diagnosisSummary ?? [],
      };
    case DialogType.PlaceOfService:
      return {
        title: 'Place of Service',
        columns: PLACE_OF_SERVICE_COLUMNS,
        rows: providerInfo?.placeOfService ?? [],
      };
    case DialogType.Prescription:
      return {
        title: 'Prescriptions',
        columns: PRESCRIPTION_SUMMARY_COLUMNS,
        rows: providerInfo?.prescriptionSummary ?? [],
      };
    case DialogType.Procedure:
      return {
        title: 'Procedures',
        columns: PROCEDURE_SUMMARY_COLUMNS,
        rows: providerInfo?.procedureSummary ?? [],
      };
    default:
      return {
        title: '',
        columns: [],
        rows: [],
      };
  }
};

const ViewAllDialog = ({
  dialogType, npi, version, open, handleClose,
}) => {
  const {
    data: providerInfo,
    isLoading,
    isFetching,
    isError,
    refetch,
  } = useGetProviderQuery({ npi, version, allRecordsSection: dialogType });

  const { title, columns, rows } = getConfig(dialogType, providerInfo);

  const render = () => {
    if (isLoading || isFetching) {
      return <LoadingIndicator />;
    }
    if (isError) {
      return (
        <Box>
          <GenericError retry={refetch} />
        </Box>
      );
    }
    return (
      <MaterialTable
        rows={rows}
        columns={columns}
        rowsPerPage={15}
        rowHeight={55}
        emptyText="No Data Available!"
      />
    );
  };

  return (
    <FullScreenDialog open={open} handleClose={handleClose}>
      <Stack spacing={2} sx={{ p: 3 }}>
        <Typography variant="h4" textAlign="left">{title}</Typography>
        {render()}
      </Stack>
    </FullScreenDialog>
  );
};

ViewAllDialog.defaultProps = {
  dialogType: null,
  npi: '',
  version: '',
  open: false,
  handleClose: null,
};

ViewAllDialog.propTypes = {
  dialogType: PropTypes.oneOf([
    DialogType.Diagnosis,
    DialogType.PlaceOfService,
    DialogType.Prescription,
    DialogType.Procedure,
    null,
  ]),
  npi: PropTypes.string,
  version: PropTypes.string,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default ViewAllDialog;
