const DEFAULT_ROW_HEIGHT = 67;
const DEFAULT_ROWS_PER_PAGE = 12;
const ACTIONS = 'actions';

const COLUMN_FILTER_TYPE = {
  default: 'default',
  date: 'date',
  multiSelect: 'multi_select',
};

const TABLE_VARIANT = {
  expanded: 'expanded',
  standard: 'standard',
};

const SORT_ORDER = {
  ascending: 'asc',
  descending: 'desc',
};

export {
  DEFAULT_ROW_HEIGHT,
  DEFAULT_ROWS_PER_PAGE,
  COLUMN_FILTER_TYPE,
  TABLE_VARIANT,
  SORT_ORDER,
  ACTIONS,
};
