const PRIMARY_ADDRESS_TYPE = 'LOCATION';

const INDIVIDUAL = 'NPI-1';
const ORGANIZATION = 'NPI-2';

const ENUMERATION_TYPES = {
  [INDIVIDUAL]: 'Individual',
  [ORGANIZATION]: 'Organization',
};

const formatName = (data, providerType) => (providerType === INDIVIDUAL
  ? `${data?.first_name} ${data?.last_name}`
  : data?.organization_name);

const formatAddressAndPhoneNumber = (addresses) => {
  const {
    address_1: address,
    city,
    state,
    postal_code: postalCode,
    telephone_number: telephoneNumber,
  } = addresses.find(({ address_purpose: type }) => type === PRIMARY_ADDRESS_TYPE);

  return {
    address: `${address} ${city}, ${state} ${postalCode}`,
    phoneNumber: telephoneNumber,
  };
};

const formatTaxonomy = (taxonomies) => {
  const { desc } = taxonomies.find((taxonomy) => taxonomy.primary);
  return desc;
};

const toProviderSearchResult = (data) => data.map((searchResult) => {
  const {
    enumeration_type: providerType,
    number,
    addresses,
    basic,
    taxonomies,
  } = searchResult;

  const { address, phoneNumber } = formatAddressAndPhoneNumber(addresses);

  return {
    npi: number,
    name: formatName(basic, providerType),
    type: ENUMERATION_TYPES[providerType],
    primaryPracticeAddress: address,
    phone: phoneNumber,
    primaryTaxonomy: formatTaxonomy(taxonomies),
  };
});

export default toProviderSearchResult;
