import React from 'react';
import { TableRow, TableCell, CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import PropTypes from 'prop-types';

const TableLoadingIndicator = ({ rowHeight, columnSpan }) => (
  <TableRow
    style={{
      height: rowHeight,
    }}
  >
    <TableCell colSpan={columnSpan}>
      <Box sx={{
        display: 'flex', justifyContent: 'center', alignItems: 'center',
      }}
      >
        <CircularProgress />
      </Box>
    </TableCell>
  </TableRow>
);

TableLoadingIndicator.propTypes = {
  rowHeight: PropTypes.number.isRequired,
  columnSpan: PropTypes.number.isRequired,
};

export default TableLoadingIndicator;
