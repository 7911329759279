import {
  Button, Stack, TextField, Typography,
} from '@mui/material';
import shake from 'common/utils/animations';
import { validateNpi } from 'common/utils/validators';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ProviderSearch = () => {
  const navigate = useNavigate();
  const [npiNumber, setNpiNumber] = useState('');
  const [validationMessage, setValidationMessage] = useState(null);

  const onSubmit = useCallback(
    () => {
      if (npiNumber && validateNpi(npiNumber)) {
        navigate(`/provider/${npiNumber}`);
      } else {
        setValidationMessage('Please enter a valid NPI number!');
      }
    },
    [npiNumber, navigate, setValidationMessage],
  );

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        onSubmit();
      }
    },
    [onSubmit],
  );

  const onNpiNumberChange = useCallback(
    (e) => {
      setNpiNumber(e.target.value);
    },
    [setNpiNumber],
  );

  return (
    <Stack alignItems="center" spacing={5} sx={{ marginTop: '20vh' }}>
      <Typography variant="h3">Provider Search</Typography>
      <Stack alignItems="flex-end">
        <TextField
          value={npiNumber}
          onChange={onNpiNumberChange}
          onKeyDown={handleKeyDown}
          sx={{
            width: 450,
            animation: validationMessage ? `${shake} 0.2s ease-in-out 0s 2` : '',
          }}
          error={validationMessage !== null}
          placeholder="Enter an NPI number..."
          helperText={validationMessage || ' '}
          variant="outlined"
          size="small"
          autoFocus
        />
        <Button
          type="submit"
          variant="contained"
          onClick={onSubmit}
          sx={{ width: 100 }}
        >
          Search
        </Button>
      </Stack>
    </Stack>
  );
};

export default ProviderSearch;
