import decode from 'jwt-decode';

const Auth = {
  userManager: null,
};

const getUserToken = async () => {
  const user = await Auth.userManager.getUser();
  return (user && user.access_token) || null;
};

const getDecodedJwt = async () => {
  const userToken = await getUserToken();

  try {
    return decode(userToken);
  } catch (_) {
    return null;
  }
};

const getUserRoles = async () => (await getDecodedJwt())?.roles;

const checkAuthorization = async (roles) => {
  const audience = window.ENV.AUTH_AUDIENCE;
  const userRoles = await getUserRoles();

  if (!userRoles || !userRoles[audience] || userRoles[audience].length === 0) {
    return false;
  }

  return roles.every((role) => userRoles[audience].includes(role));
};

export {
  Auth,
  getUserToken,
  checkAuthorization,
};
