import { createTheme } from '@mui/material';
import appColors from 'assets/colors/appColors';

const headerFont = "'Poppins', sans-serif";

const theme = createTheme({
  palette: {
    primary: {
      main: appColors.purple,
    },
    text: {
      primary: appColors.black,
    },
  },
  typography: {
    h1: {
      fontFamily: headerFont,
      fontWeight: 'bold',
    },
    h2: {
      fontFamily: headerFont,
      fontWeight: 'bold',
    },
    h3: {
      fontFamily: headerFont,
      fontWeight: 'bold',
    },
    h4: {
      fontFamily: headerFont,
      fontWeight: 'bold',
    },
    h5: {
      fontFamily: headerFont,
      fontWeight: 'bold',
    },
    h6: {
      fontFamily: headerFont,
      fontWeight: 'bold',
    },
    button: {
      fontWeight: 'bold',
      textTransform: 'none',
    },
  },
  components: {
    MuiTableSortLabel: {
      styleOverrides: {
        icon: {
          fill: appColors.purple,
        },
        root: {
          '&.Mui-active': {
            color: appColors.purple,
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          marginTop: 2,
          fontSize: '1.5rem',
          fontWeight: 'bold',
          fontFamily: headerFont,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          padding: 4,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '1em',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: 'white',
        },
      },
    },
  },
});

export default theme;
