/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Collapse, IconButton, TableCell, TableRow,
} from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Box } from '@mui/system';
import TableRowCells from './TableRowCells';

const ExpandedRow = ({
  row, columns, renderExpandedSection, tableMetaData,
}) => {
  const COLUMN_SPAN = columns.length + 1;
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell sx={{ borderBottom: 'unset', py: 2.5 }}>
          <IconButton
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableRowCells row={row} columns={columns} tableMetaData={tableMetaData} />
      </TableRow>
      {
        renderExpandedSection && (
          <TableRow>
            <TableCell style={{ padding: 0 }} colSpan={COLUMN_SPAN}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box style={{ paddingLeft: 75, paddingBottom: 32 }}>
                  {renderExpandedSection(row)}
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        )
      }
    </>
  );
};

ExpandedRow.defaultProps = {
  tableMetaData: {},
};

ExpandedRow.propTypes = {
  row: PropTypes.any.isRequired,
  columns: PropTypes.arrayOf(PropTypes.any).isRequired,
  renderExpandedSection: PropTypes.func.isRequired,
  tableMetaData: PropTypes.any,
};

export default ExpandedRow;
