import { keyframes } from '@emotion/react';

const shake = keyframes`
  0% {
    margin-left: 0;
  }
  25% {
    margin-left: 0.5rem;
  }
  75% {
    margin-left: -0.5rem;
  }
  100% {
    margin-left: 0;
  }
`;

export default shake;
