import React, { useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { useParams, useSearchParams } from 'react-router-dom';
import ProfileBreadcrumbs from './components/ProfileBreadcrumbs';
import OverviewSection from './components/OverviewSection';
import PaymentSummaryTable from './components/PaymentSummaryTable';
import DiagnosisSummaryTable from './components/DiagnosisSummaryTable';
import ProcedureSummaryTable from './components/ProcedureSummaryTable';
import PrescriptionSummaryTable from './components/PrescriptionSummaryTable';
import PlaceOfServiceTable from './components/PlaceOfServiceTable';
import { useGetProviderQuery } from '../../api/provider/providerApi';
import LoadingIndicator from '../../common/components/LoadingIndicator/LoadingIndicator';
import ProviderNotFoundMessage from './components/ProviderNotFoundMessage';
import ProfileSection from './components/ProfileSection';
import ViewAllDialog, { DialogType } from './components/ViewAllDialog';

const ProviderProfile = () => {
  const { npi } = useParams();
  const [searchParams] = useSearchParams();
  const version = searchParams.get('version');
  const { data: providerInfo, isLoading, isError } = useGetProviderQuery({ npi, version });
  const [dialogType, setDialogType] = useState(null);

  const handleCloseDialog = () => setDialogType(null);

  if (isLoading) return <LoadingIndicator />;
  if (isError) return <ProviderNotFoundMessage />;

  return (
    <Stack spacing={5} sx={{ flexGrow: 1 }}>
      <Stack spacing={2}>
        <Typography variant="h4" textAlign="left">{providerInfo?.name}</Typography>
        <ProfileBreadcrumbs providerName={providerInfo?.name} />
        <OverviewSection
          specialties={providerInfo?.specialties}
          licenses={providerInfo?.licenses}
          practiceAddress={providerInfo?.practiceAddress}
          mailingAddress={providerInfo?.mailingAddress}
        />
      </Stack>
      <ProfileSection
        title="Payment Summary"
        displayedRecordCount={providerInfo?.paymentSummary?.length}
        totalRecordCount={providerInfo?.paymentSummaryTotalRecords}
        hideViewAll
      >
        <PaymentSummaryTable paymentSummary={providerInfo?.paymentSummary} />
      </ProfileSection>
      <ProfileSection
        title="Procedure Summary"
        displayedRecordCount={providerInfo?.procedureSummary?.length}
        totalRecordCount={providerInfo?.procedureSummaryTotalRecords}
        onViewAllClicked={() => {
          setDialogType(DialogType.Procedure);
        }}
      >
        <ProcedureSummaryTable procedureSummary={providerInfo?.procedureSummary} />
      </ProfileSection>
      <ProfileSection
        title="Diagnosis Summary"
        displayedRecordCount={providerInfo?.diagnosisSummary?.length}
        totalRecordCount={providerInfo?.diagnosisSummaryTotalRecords}
        onViewAllClicked={() => {
          setDialogType(DialogType.Diagnosis);
        }}
      >
        <DiagnosisSummaryTable diagnosisSummary={providerInfo?.diagnosisSummary} />
      </ProfileSection>
      <ProfileSection
        title="Prescription Summary"
        displayedRecordCount={providerInfo?.prescriptionSummary?.length}
        totalRecordCount={providerInfo?.prescriptionSummaryTotalRecords}
        onViewAllClicked={() => {
          setDialogType(DialogType.Prescription);
        }}
      >
        <PrescriptionSummaryTable prescriptionSummary={providerInfo?.prescriptionSummary} />
      </ProfileSection>
      <ProfileSection
        title="Place of Service"
        displayedRecordCount={providerInfo?.placeOfService?.length}
        totalRecordCount={providerInfo?.placeOfServiceSummaryTotalRecords}
        onViewAllClicked={() => {
          setDialogType(DialogType.PlaceOfService);
        }}
      >
        <PlaceOfServiceTable placeOfServiceSummary={providerInfo?.placeOfService} />
      </ProfileSection>
      <ViewAllDialog
        dialogType={dialogType}
        npi={npi}
        version={version}
        open={dialogType !== null}
        handleClose={handleCloseDialog}
      />
    </Stack>
  );
};

export default ProviderProfile;
