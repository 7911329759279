import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { checkAuthorization } from 'auth/auth';
import UnauthorizedMessage from './UnauthorizedMessage';

const ProtectedRoute = ({ authRoles, children }) => {
  const [isAuthorized, setIsAuthorized] = useState(true);

  const checkAuth = async () => {
    const isAuthed = await checkAuthorization(authRoles);
    setIsAuthorized(isAuthed);
  };

  useEffect(() => {
    checkAuth();
  });

  return isAuthorized ? children : <UnauthorizedMessage />;
};

ProtectedRoute.propTypes = {
  authRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  children: PropTypes.node.isRequired,
};

export default ProtectedRoute;
