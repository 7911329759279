/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Dialog, IconButton, Slide } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import appColors from '../../assets/colors/appColors';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const FullScreenDialog = ({
  open,
  handleClose,
  children,
}) => (
  <Dialog
    fullScreen
    open={open}
    onClose={handleClose}
    TransitionComponent={Transition}
    PaperProps={{ sx: { padding: 0 } }}
  >
    <IconButton
      edge="end"
      color="inherit"
      onClick={handleClose}
      sx={{
        position: 'absolute',
        right: '32px',
        top: '8px',
        zIndex: 9999,
      }}
    >
      <CloseIcon htmlColor={appColors.black} />
    </IconButton>
    {children}
  </Dialog>
);

FullScreenDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default FullScreenDialog;
