import React from 'react';
import PropTypes from 'prop-types';
import MaterialTable from '../../../common/components/MaterialTable/MaterialTable';
import {
  COLUMN_FILTER_TYPE,
  SORT_ORDER,
} from '../../../common/components/MaterialTable/tableConstants';
import { formatCurrency } from '../../../common/utils/formatting';

export const PRESCRIPTION_SUMMARY_COLUMNS = [
  {
    id: 'id',
    label: 'NDC Code',
    width: '10%',
    isFilterEnabled: true,
    filterType: COLUMN_FILTER_TYPE.default,
    renderCell: (row) => {
      const { id } = row;
      return <div>{id}</div>;
    },
  },
  {
    id: 'ndcCodeDescription',
    label: 'Description',
    width: '10%',
    isFilterEnabled: true,
    filterType: COLUMN_FILTER_TYPE.default,
    renderCell: (row) => {
      const { ndcCodeDescription } = row;
      return <div>{ndcCodeDescription}</div>;
    },
  },
  {
    id: 'brandName',
    label: 'Brand Name',
    width: '10%',
    isFilterEnabled: true,
    filterType: COLUMN_FILTER_TYPE.default,
    renderCell: (row) => {
      const { brandName } = row;
      return <div>{brandName}</div>;
    },
  },
  {
    id: 'genericName',
    label: 'Generic Name',
    width: '10%',
    isFilterEnabled: true,
    filterType: COLUMN_FILTER_TYPE.default,
    renderCell: (row) => {
      const { genericName } = row;
      return <div>{genericName}</div>;
    },
  },
  {
    id: 'schedule',
    label: 'Schedule',
    width: '10%',
    isFilterEnabled: true,
    filterType: COLUMN_FILTER_TYPE.default,
    renderCell: (row) => {
      const { schedule } = row;
      return <div>{schedule}</div>;
    },
  },
  {
    id: 'totalNumberDistinctRecipients',
    label: 'Total Number of Distinct Recipients',
    width: '10%',
    isFilterEnabled: true,
    filterType: COLUMN_FILTER_TYPE.default,
    renderCell: (row) => {
      const { totalNumberDistinctRecipients } = row;
      return <div>{totalNumberDistinctRecipients}</div>;
    },
  },
  {
    id: 'totalNumberClaims',
    label: 'Total Number of Claims (Prescriptions)',
    width: '10%',
    isFilterEnabled: true,
    filterType: COLUMN_FILTER_TYPE.default,
    renderCell: (row) => {
      const { totalNumberClaims } = row;
      return <div>{totalNumberClaims}</div>;
    },
  },
  {
    id: 'claimsPerRecipient',
    label: 'Number of Claims (Prescriptions) Per Recipient',
    width: '10%',
    isFilterEnabled: true,
    filterType: COLUMN_FILTER_TYPE.default,
    renderCell: (row) => {
      const { claimsPerRecipient } = row;
      return <div>{claimsPerRecipient}</div>;
    },
  },
  {
    id: 'totalRefills',
    label: 'Total Number of Refills',
    width: '10%',
    isFilterEnabled: true,
    filterType: COLUMN_FILTER_TYPE.default,
    renderCell: (row) => {
      const { totalRefills } = row;
      return <div>{totalRefills}</div>;
    },
  },
  {
    id: 'totalPaid',
    label: 'Total Prescription Paid Amount',
    width: '10%',
    isFilterEnabled: true,
    filterType: COLUMN_FILTER_TYPE.default,
    renderCell: (row) => {
      const { totalPaid } = row;
      return <div>{formatCurrency(totalPaid)}</div>;
    },
  },
];

const PrescriptionSummaryTable = ({ prescriptionSummary }) => (
  <MaterialTable
    rows={prescriptionSummary}
    columns={PRESCRIPTION_SUMMARY_COLUMNS}
    initialOrder={SORT_ORDER.descending}
    initialOrderBy="totalPaid"
    rowsPerPage={prescriptionSummary?.length || 1}
    hideFilterRow
    hidePaginationRow
    emptyText="No Data Available!"
  />
);

PrescriptionSummaryTable.defaultProps = {
  prescriptionSummary: [],
};

PrescriptionSummaryTable.propTypes = {
  prescriptionSummary: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    ndcCodeDescription: PropTypes.string,
    brandName: PropTypes.string,
    generic: PropTypes.string,
    schedule: PropTypes.string,
    totalNumberDistinctRecipients: PropTypes.number,
    totalNumberClaims: PropTypes.number,
    claimsPerRecipient: PropTypes.number,
    totalRefills: PropTypes.number,
    totalPaid: PropTypes.number,
  })),
};
export default PrescriptionSummaryTable;
