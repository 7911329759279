import React, { useEffect } from 'react';
import Routes from 'app/Routes';
import SideNav from 'common/components/SideNav/SideNav';
import { Box } from '@mui/material';
import LoadingIndicator from 'common/components/LoadingIndicator/LoadingIndicator';
import { Auth } from 'auth/auth';
import { useAuth } from 'oidc-react';
import appColors from '../assets/colors/appColors';

const App = () => {
  const { userManager, isLoading } = useAuth();

  useEffect(() => {
    Auth.userManager = userManager;
  }, [userManager]);

  return (
    <Box sx={{ textAlign: 'center', display: 'flex' }}>
      <SideNav />
      <Box sx={{
        width: '100vw',
        height: '100vh',
        overflow: 'scroll',
        padding: '3vh 2vw',
        backgroundColor: appColors.white,
      }}
      >
        {isLoading ? <LoadingIndicator /> : <Routes />}
      </Box>
    </Box>
  );
};

export default App;
