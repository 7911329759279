import React, { useState } from 'react';
import './SideNav.scss';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import InfoIcon from '@mui/icons-material/Info';
import LogoutIcon from '@mui/icons-material/Logout';
import { Button } from '@mui/material';
import { useAuth } from 'oidc-react';
import NavMenuItem from './NavMenuItem';
import NavLogo from './NavLogo';

export const menuRoutes = [
  {
    label: 'Provider',
    path: '/provider',
    icon: PersonSearchIcon,
  },
  {
    label: 'About',
    path: '/about',
    icon: InfoIcon,
  },
];

const menuItems = menuRoutes.map((route) => <NavMenuItem key={route.label} route={route} />);

const SideNav = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpanded = () => setIsExpanded(!isExpanded);
  const { signOutRedirect } = useAuth();

  return (
    <nav className={`side-nav${isExpanded ? ' side-nav--expanded' : ''}`}>
      <NavLogo />
      <div className="nav-container">
        <div
          role="none"
          onClick={toggleExpanded}
          className={`toggle-btn${isExpanded ? ' toggle-btn--expanded' : ''}`}
        />
        <div>{menuItems}</div>
        <div>
          <Button className="menu__item" onClick={signOutRedirect}>
            <div className="link-container">
              <LogoutIcon htmlColor="white" />
              <span className="menu__item__caption" style={{ color: 'white' }}>Log out</span>
            </div>
          </Button>
        </div>
      </div>
    </nav>
  );
};

export default SideNav;
