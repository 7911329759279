/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import MaterialTable from '../../../common/components/MaterialTable/MaterialTable';

const ROW_HEIGHT = 50;
const ROWS_PER_PAGE = 15;
const COLUMNS = [
  {
    id: 'npi',
    label: 'NPI',
    width: '12%',
    renderCell: (row) => {
      const { npi } = row;
      return <div>{npi}</div>;
    },
  },
  {
    id: 'name',
    label: 'Name',
    width: '16%',
    renderCell: (row) => {
      const { name } = row;
      return <div>{name}</div>;
    },
  },
  {
    id: 'type',
    label: 'NPI Type',
    width: '12%',
    renderCell: (row) => {
      const { type } = row;
      return <div>{type}</div>;
    },
  },
  {
    id: 'primaryPracticeAddress',
    label: 'Primary Practice Address',
    width: '20%',
    renderCell: (row) => {
      const { primaryPracticeAddress } = row;
      return <div>{primaryPracticeAddress}</div>;
    },
  },
  {
    id: 'phone',
    label: 'Phone',
    width: '12%',
    renderCell: (row) => {
      const { phone } = row;
      return <div>{phone}</div>;
    },
  },
  {
    id: 'primaryTaxonomy',
    label: 'Primary Taxonomy',
    width: '16%',
    renderCell: (row) => {
      const { primaryTaxonomy } = row;
      return <div>{primaryTaxonomy}</div>;
    },
  },
  {
    id: 'view',
    label: '',
    width: '8%',
    renderCell: (row) => {
      const { npi } = row;
      return (
        <Button variant="outlined" size="small" href={`${npi}`}>
          View
        </Button>
      );
    },
  },
];

const SearchResultsTable = ({ rows, isLoading }) => (
  <MaterialTable
    rows={rows}
    columns={COLUMNS}
    isLoading={isLoading}
    rowHeight={ROW_HEIGHT}
    rowsPerPage={ROWS_PER_PAGE}
    hideFilterRow
  />
);

SearchResultsTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.any).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default SearchResultsTable;
