import React from 'react';
import { Navigate, Route, Routes as ReactRoutes } from 'react-router-dom';
import About from 'pages/About/About';
import ProviderSearch from 'pages/ProviderSearch/ProviderSearch';
import ProviderSearchV2 from 'pages/ProviderSearchV2/ProviderSearch';
import ProtectedRoute from 'common/components/ProtectedRoute';
import AUTH_CONSTANTS from 'auth/authConstants';
import ProviderProfile from '../pages/ProviderProfile/ProviderProfile';

const routes = [
  {
    path: '/provider',
    element: <ProviderSearch />,
    isProtected: true,
    authRoles: [
      AUTH_CONSTANTS.user_role,
    ],
  },
  {
    path: '/provider/v2',
    element: <ProviderSearchV2 />,
    isProtected: true,
    authRoles: [
      AUTH_CONSTANTS.user_role,
    ],
  },
  {
    path: '/provider/:npi',
    element: <ProviderProfile />,
    isProtected: true,
    authRoles: [
      AUTH_CONSTANTS.user_role,
    ],
  },
  {
    path: '/about',
    element: <About />,
    isProtected: true,
    authRoles: [
      AUTH_CONSTANTS.user_role,
    ],
  },
  {
    path: '/auth/redirect',
    element: <Navigate replace to="/provider" />,
    isProtected: false,
  },
];

const renderProtectedRoute = (key, element, authRoles) => (
  <ProtectedRoute
    key={`${key}_protected_route`}
    authRoles={authRoles}
  >
    {element}
  </ProtectedRoute>
);

const Routes = () => (
  <ReactRoutes>
    {routes.map(({
      path, element, isProtected, authRoles,
    }) => {
      const item = isProtected ? renderProtectedRoute(path, element, authRoles) : element;
      return <Route key={`${path}_route`} path={path} element={item} />;
    })}
  </ReactRoutes>
);

export default Routes;
