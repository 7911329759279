import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';

const StyledLink = ({ to, text }) => (
  <Typography
    component={Link}
    to={to}
    color="primary"
  >
    {text}
  </Typography>
);

StyledLink.propTypes = {
  to: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default StyledLink;
