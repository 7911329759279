/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableHead, TableRow } from '@mui/material';
import DefaultFilter from './DefaultFilter';
import DateRangeFilter from './DateRangeFilter';
import MultiSelectFilter from './MultiSelectFilter';
import { ACTIONS, COLUMN_FILTER_TYPE } from '../tableConstants';

const renderFilterByType = (column, onFilterChanged) => {
  let filter;

  if (!column.isFilterEnabled) {
    filter = <div />;
  } else if (column.filterType === COLUMN_FILTER_TYPE.date) {
    filter = (
      <DateRangeFilter
        key={`${column.id}-filter`}
        columnConfig={column}
        onFilterChanged={onFilterChanged}
      />
    );
  } else if (column.filterType === COLUMN_FILTER_TYPE.multiSelect) {
    filter = (
      <MultiSelectFilter
        key={`${column.id}-filter`}
        columnConfig={column}
        onFilterChanged={onFilterChanged}
      />
    );
  } else {
    filter = (
      <DefaultFilter
        key={`${column.id}-filter`}
        columnConfig={column}
        onFilterChanged={onFilterChanged}
      />
    );
  }

  return filter;
};

const TableFilterRow = ({ columns, isExpandedTable, onFilterChanged }) => (
  <TableHead>
    <TableRow>
      {isExpandedTable && <TableCell />}
      {columns.map((column) => {
        if (column.id !== ACTIONS) {
          return (
            <TableCell key={`${column.id}-filter`}>
              {renderFilterByType(column, onFilterChanged)}
            </TableCell>
          );
        }
        return <div key="action-empty-filter" />;
      })}
    </TableRow>
  </TableHead>
);

TableFilterRow.defaultProps = {
  isExpandedTable: false,
};

TableFilterRow.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.any).isRequired,
  isExpandedTable: PropTypes.bool,
  onFilterChanged: PropTypes.func.isRequired,
};

export default TableFilterRow;
