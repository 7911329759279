/* eslint react/jsx-props-no-spreading: "off" */
import React, { StrictMode } from 'react';
import reportWebVitals from 'reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from 'oidc-react';
import App from 'app/App';
import store from 'app/store';
import { Provider } from 'react-redux';
import oidcConfig from 'auth/oidcConfig';
import { createRoot } from 'react-dom/client';
import { CssBaseline, ThemeProvider } from '@mui/material';
import theme from './app/theme';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <StrictMode>
    <AuthProvider {...oidcConfig}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Router>
            <CssBaseline />
            <App />
          </Router>
        </ThemeProvider>
      </Provider>
    </AuthProvider>
  </StrictMode>,
);

reportWebVitals();
