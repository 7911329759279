const formatZipCode = (zip) => {
  if (zip.length < 6) {
    return zip;
  }
  return (`${zip.substring(0, 5)}-${zip.substring(5)}`);
};

const toProperCase = (input) => {
  const toLower = input.toLowerCase();
  return toLower.charAt(0).toUpperCase() + toLower.slice(1);
};

const formatCurrency = (value) => {
  try {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });

    return formatter.format(value);
  } catch (_) {
    return '';
  }
};

export {
  formatZipCode,
  toProperCase,
  formatCurrency,
};
