/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import {
  TableCell, TableHead, TableRow, TableSortLabel,
} from '@mui/material';
import { Box } from '@mui/system';
import { visuallyHidden } from '@mui/utils';
import { ACTIONS, SORT_ORDER } from './tableConstants';
import TooltipWrapper from '../TooltipWrapper';

const SortableTableHead = ({
  columns, order, orderBy, onRequestSort, leftHeaderPadding,
}) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {leftHeaderPadding && <TableCell sx={{ width: leftHeaderPadding }} />}
        {columns.map((column) => (
          <TableCell
            key={`${column.id}-cell`}
            align="left"
            padding="normal"
            sortDirection={orderBy === column.id ? order : false}
            sx={{
              width: `${column.width}`,
              borderLeft: column.id === ACTIONS ? '1px solid lightGray' : '',
            }}
          >
            {column.isSortable !== false && column.id !== ACTIONS ? (
              <TableSortLabel
                key={`${column.id}-sort-label`}
                active={orderBy === column.id}
                direction={orderBy === column.id ? order : SORT_ORDER.ascending}
                onClick={createSortHandler(column.id)}
              >
                <TooltipWrapper key={`${column.id}-tooltip`} tooltipText={column.tooltip}>
                  <strong key={`${column.id}-column-label`}>{column.label}</strong>
                </TooltipWrapper>
                {column === column.id ? (
                  <Box component="span" sx={visuallyHidden} key={`${column.id}-box`}>
                    {order === SORT_ORDER.descending ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : <strong key={`${column.id}-column-label`}>{column.label}</strong>}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

SortableTableHead.defaultProps = {
  leftHeaderPadding: null,
};

SortableTableHead.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.any).isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  leftHeaderPadding: PropTypes.string,
};

export default SortableTableHead;
