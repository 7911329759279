import React from 'react';
import {
  TableRow, TableCell, Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import PropTypes from 'prop-types';

const TextTableOverlay = ({ text, rowHeight, columnSpan }) => (
  <TableRow
    style={{
      height: rowHeight,
    }}
  >
    <TableCell colSpan={columnSpan}>
      <Box sx={{
        display: 'flex', justifyContent: 'center', alignItems: 'center',
      }}
      >
        <Typography>{ text }</Typography>
      </Box>
    </TableCell>
  </TableRow>
);

TextTableOverlay.propTypes = {
  text: PropTypes.string.isRequired,
  rowHeight: PropTypes.number.isRequired,
  columnSpan: PropTypes.number.isRequired,
};

export default TextTableOverlay;
