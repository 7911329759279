const appColors = {
  black: '#3E4041',
  purple: '#6d33df',
  red: '#E71D36',
  green: '#25B963',
  gray: '#3E404180',
  darkBlue: '#17212c',
  lightGray: '#DEDEDE',
  white: '#F7F7F7',
};

export default appColors;
