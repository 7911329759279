import React from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import HelpIcon from '@mui/icons-material/HelpOutline';
import appColors from 'assets/colors/appColors';
import { Box } from '@mui/system';
import LightTooltip from './LightTooltip';

const TooltipWrapper = ({
  tooltipText,
  children,
}) => (
  <Stack direction="row" spacing={0.5} alignItems="flex-end">
    {children}
    {tooltipText && (
    <Box sx={{ paddingBottom: '2px' }}>
      <LightTooltip title={tooltipText}>
        <HelpIcon htmlColor={appColors.purple} fontSize="small" />
      </LightTooltip>
    </Box>
    )}
  </Stack>
);

TooltipWrapper.defaultProps = {
  tooltipText: null,
};

TooltipWrapper.propTypes = {
  tooltipText: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default TooltipWrapper;
