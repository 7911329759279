import React from 'react';
import PropTypes from 'prop-types';
import MaterialTable from '../../../common/components/MaterialTable/MaterialTable';
import {
  COLUMN_FILTER_TYPE,
  SORT_ORDER,
} from '../../../common/components/MaterialTable/tableConstants';
import { formatCurrency } from '../../../common/utils/formatting';

export const PLACE_OF_SERVICE_COLUMNS = [
  {
    id: 'id',
    label: 'Place of Service Code',
    width: '14%',
    isFilterEnabled: true,
    filterType: COLUMN_FILTER_TYPE.default,
    renderCell: (row) => {
      const { id } = row;
      return <div>{id}</div>;
    },
  },
  {
    id: 'placeOfServiceDescription',
    label: 'Place of Service Description',
    width: '14%',
    isFilterEnabled: true,
    filterType: COLUMN_FILTER_TYPE.default,
    renderCell: (row) => {
      const { placeOfServiceDescription } = row;
      return <div>{placeOfServiceDescription}</div>;
    },
  },
  {
    id: 'totalNumberDistinctRecipients',
    label: 'Total Number of Distinct Recipients',
    width: '14%',
    isFilterEnabled: true,
    filterType: COLUMN_FILTER_TYPE.default,
    renderCell: (row) => {
      const { totalNumberDistinctRecipients } = row;
      return <div>{totalNumberDistinctRecipients}</div>;
    },
  },
  {
    id: 'totalNumberClaims',
    label: 'Total Number of Claims',
    width: '14%',
    isFilterEnabled: true,
    filterType: COLUMN_FILTER_TYPE.default,
    renderCell: (row) => {
      const { totalNumberClaims } = row;
      return <div>{totalNumberClaims}</div>;
    },
  },
  {
    id: 'totalBilled',
    label: 'Total Billed',
    width: '14%',
    isFilterEnabled: true,
    filterType: COLUMN_FILTER_TYPE.default,
    renderCell: (row) => {
      const { totalBilled } = row;
      return <div>{formatCurrency(totalBilled)}</div>;
    },
  },
  {
    id: 'totalPaid',
    label: 'Total Paid',
    width: '14%',
    isFilterEnabled: true,
    filterType: COLUMN_FILTER_TYPE.default,
    renderCell: (row) => {
      const { totalPaid } = row;
      return <div>{formatCurrency(totalPaid)}</div>;
    },
  },
  {
    id: 'avgPaidPerPatient',
    label: 'Average Paid Per Patient',
    width: '14%',
    isFilterEnabled: true,
    filterType: COLUMN_FILTER_TYPE.default,
    renderCell: (row) => {
      const { avgPaidPerPatient } = row;
      return <div>{formatCurrency(avgPaidPerPatient)}</div>;
    },
  },
];

const PlaceOfServiceTable = ({ placeOfServiceSummary }) => (
  <MaterialTable
    rows={placeOfServiceSummary}
    columns={PLACE_OF_SERVICE_COLUMNS}
    initialOrder={SORT_ORDER.descending}
    initialOrderBy="totalPaid"
    rowsPerPage={placeOfServiceSummary?.length || 1}
    hideFilterRow
    hidePaginationRow
    emptyText="No Data Available!"
  />
);

PlaceOfServiceTable.defaultProps = {
  placeOfServiceSummary: [],
};

PlaceOfServiceTable.propTypes = {
  placeOfServiceSummary: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    placeOfServiceDescription: PropTypes.string,
    totalNumberDistinctRecipients: PropTypes.number,
    totalNumberClaims: PropTypes.number,
    totalBilled: PropTypes.number,
    totalPaid: PropTypes.number,
    avgPaidPerPatient: PropTypes.number,
  })),
};
export default PlaceOfServiceTable;
