import React from 'react';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import { Button, Stack, Typography } from '@mui/material';
import appColors from 'assets/colors/appColors';
import PropTypes from 'prop-types';

const GenericError = ({ retry }) => (
  <Stack
    spacing={2}
    alignItems="center"
    sx={{ marginTop: 24 }}
  >
    <ErrorIcon sx={{ fontSize: 150, color: appColors.red }} />
    <Stack spacing={3} alignItems="center">
      <Stack alignItems="center">
        <Typography fontWeight="bold" fontSize={32}>
          Oops!
        </Typography>
        <Typography fontSize={18} sx={{ color: '#808080' }}>
          Something went wrong. Tap below to retry.
        </Typography>
      </Stack>
      {
        retry && <Button variant="contained" size="large" onClick={retry}>Retry</Button>
      }
    </Stack>
  </Stack>
);

GenericError.defaultProps = {
  retry: null,
};

GenericError.propTypes = {
  retry: PropTypes.func,
};
export default GenericError;
