import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Auth, getUserToken } from '../auth/auth';

const baseQuery = fetchBaseQuery({
  baseUrl: window.ENV.API_BASE_URL,
  prepareHeaders: async (headers) => {
    const token = await getUserToken();
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWith401Logout = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401 && Auth.userManager) {
    Auth.userManager.signoutRedirect();
  }

  return result;
};

export default createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWith401Logout,
  tagTypes: ['Provider', 'Status', 'ProviderSearchResult'],
  endpoints: () => ({}),
});
