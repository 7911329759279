/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { InputAdornment, TextField, Tooltip } from '@mui/material';
import FilterIcon from '@mui/icons-material/FilterList';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { COLUMN_FILTER_TYPE } from '../tableConstants';

const DateRangeFilter = ({ columnConfig, onFilterChanged }) => {
  const [filterValue, setFilterValue] = useState(null);
  const handleDateChange = (date) => {
    setFilterValue(date);
    onFilterChanged(columnConfig.id, COLUMN_FILTER_TYPE.date, date);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        value={filterValue}
        onChange={handleDateChange}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Tooltip title="Filter">
                    <FilterIcon />
                  </Tooltip>
                </InputAdornment>
              ),
              endAdornment: params.InputProps.endAdornment,
            }}
            sx={{ width: '95%' }}
          />
        )}
        clearable
      />
    </LocalizationProvider>
  );
};

DateRangeFilter.propTypes = {
  columnConfig: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    renderCell: PropTypes.func,
    isSortable: PropTypes.bool,
    width: PropTypes.string,
    isFilterEnabled: PropTypes.bool,
    filterType: PropTypes.string,
    multiSelectValues: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  onFilterChanged: PropTypes.func.isRequired,
};

export default DateRangeFilter;
