import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { Button, Stack, Typography } from '@mui/material';
import appColors from 'assets/colors/appColors';
import { useNavigate } from 'react-router-dom';

const ProviderNotFoundMessage = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  return (
    <Stack
      spacing={4}
      alignItems="center"
      sx={{ marginTop: 24 }}
    >
      <SearchIcon sx={{ fontSize: 175, color: appColors.darkBlue }} />
      <Stack spacing={1} alignItems="center">
        <Typography fontWeight="bold" fontSize={32}>
          Provider Not Found
        </Typography>
        <Stack sx={{ color: '#808080' }}>
          <Typography fontSize={18}>
            We couldn&apos;t find what you were searching for.
          </Typography>
          <Typography fontSize={18}>
            Try searching again.
          </Typography>
        </Stack>
      </Stack>
      <Button variant="contained" size="large" onClick={goBack}>Search Again</Button>
    </Stack>
  );
};

export default ProviderNotFoundMessage;
