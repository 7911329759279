import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  FormControl, Input, InputAdornment, ListItemText, MenuItem, Select, Tooltip,
} from '@mui/material';
import FilterIcon from '@mui/icons-material/FilterList';
import { COLUMN_FILTER_TYPE } from '../tableConstants';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MultiSelectFilter = ({ columnConfig, onFilterChanged }) => {
  const [filterValues, setFilterValues] = useState([]);

  const handleFilterChange = (event) => {
    const { value } = event.target;
    const filters = typeof value === 'string' ? value.split(',') : value;
    setFilterValues(filters);
    onFilterChanged(columnConfig.id, COLUMN_FILTER_TYPE.multiSelect, filters);
  };

  return (
    <FormControl sx={{ width: '95%' }}>
      <Select
        multiple
        value={filterValues}
        onChange={handleFilterChange}
        input={(
          <Input
            startAdornment={(
              <InputAdornment position="start">
                <Tooltip title="Filter">
                  <FilterIcon />
                </Tooltip>
              </InputAdornment>
              )}
          />
        )}
        renderValue={(selected) => selected.join(', ')}
        MenuProps={MenuProps}
      >
        {columnConfig.multiSelectValues && columnConfig.multiSelectValues.map((item) => (
          <MenuItem key={item} value={item}>
            <Checkbox checked={filterValues.indexOf(item) > -1} />
            <ListItemText primary={item} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

MultiSelectFilter.propTypes = {
  columnConfig: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    renderCell: PropTypes.func,
    isSortable: PropTypes.bool,
    width: PropTypes.string,
    isFilterEnabled: PropTypes.bool,
    filterType: PropTypes.string,
    multiSelectValues: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  onFilterChanged: PropTypes.func.isRequired,
};

export default MultiSelectFilter;
