import React, { useEffect, useState } from 'react';
import {
  debounce, InputAdornment, TextField, Tooltip,
} from '@mui/material';
import PropTypes from 'prop-types';
import FilterIcon from '@mui/icons-material/FilterList';
import { COLUMN_FILTER_TYPE } from '../tableConstants';

const DefaultFilter = ({ columnConfig, onFilterChanged }) => {
  const [filterText, setFilterText] = useState('');

  const onFilterChangedDebounced = debounce(() => {
    onFilterChanged(columnConfig.id, COLUMN_FILTER_TYPE.default, filterText);
  }, 300);

  useEffect(() => {
    onFilterChangedDebounced();
  }, [filterText]);

  const handleFilterChanged = (event) => {
    setFilterText(event.target.value);
  };

  return (
    <TextField
      variant="standard"
      type="search"
      value={filterText}
      onChange={handleFilterChanged}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Tooltip title="Filter">
              <FilterIcon />
            </Tooltip>
          </InputAdornment>
        ),
      }}
      sx={{ width: '95%' }}
    />
  );
};

DefaultFilter.propTypes = {
  columnConfig: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    renderCell: PropTypes.func,
    isSortable: PropTypes.bool,
    width: PropTypes.string,
    isFilterEnabled: PropTypes.bool,
    filterType: PropTypes.string,
    multiSelectValues: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  onFilterChanged: PropTypes.func.isRequired,
};

export default DefaultFilter;
