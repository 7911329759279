/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Paper, Table, TableBody, TableCell, TableContainer, TableRow,
} from '@mui/material';
import isSameDay from 'date-fns/isSameDay';
import { getComparator, stableSort } from 'common/components/MaterialTable/tableUtils';
import SortableTableHead from 'common/components/MaterialTable/SortableTableHead';
import TableLoadingIndicator from 'common/components/MaterialTable/TableLoadingIndicator';
import TablePaginationFooter from 'common/components/MaterialTable/TablePaginationFooter';
import { isNonEmptyArray, isNonEmptyString, isValidDate } from 'common/utils/validators';
import ExpandedRow from './ExpandedRow';
import TableRowCells from './TableRowCells';
import TableFilterRow from './column-filters/TableFilterRow';
import {
  COLUMN_FILTER_TYPE,
  DEFAULT_ROW_HEIGHT,
  DEFAULT_ROWS_PER_PAGE,
  SORT_ORDER,
  TABLE_VARIANT,
} from './tableConstants';
import TextTableOverlay from './TextTableOverlay';

const MaterialTable = ({
  rows,
  columns,
  isLoading,
  initialOrder,
  initialOrderBy,
  variant,
  rowHeight,
  rowsPerPage,
  renderExpandedSection,
  onActionItemClicked,
  hideFilterRow,
  hidePaginationRow,
  emptyText,
  tableMetaData,
}) => {
  const COLUMN_SPAN = variant === TABLE_VARIANT.expanded ? columns.length + 1 : columns.length;

  const [order, setOrder] = useState(initialOrder);
  const [orderBy, setOrderBy] = useState(initialOrderBy);
  const [page, setPage] = useState(1);
  const [columnFilters, setColumnFilters] = useState([]);

  const emptyRows = Math.max(0, page * rowsPerPage - rows.length);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleRequestSort = (_, property) => {
    const isAsc = orderBy === property && order === SORT_ORDER.ascending;
    setOrder(isAsc ? SORT_ORDER.descending : SORT_ORDER.ascending);
    setOrderBy(property);
  };

  const onColumnFilterChanged = (columnId, filterType, filterValue) => {
    const newFilters = [...columnFilters].filter((filter) => filter.columnId !== columnId);

    if (isNonEmptyString(filterValue)
      || isNonEmptyArray(filterValue)
      || isValidDate(filterValue)) {
      newFilters.push({ columnId, filterType, filterValue });
    }

    setColumnFilters(newFilters);
  };

  const applyColumnFilters = (dataRows) => {
    let filteredRows = [...dataRows];

    columnFilters.forEach((filter) => {
      if (filter.filterType === COLUMN_FILTER_TYPE.default) {
        filteredRows = filteredRows.filter((row) => row[filter.columnId]
          .toString().toLowerCase().includes(filter.filterValue.toLowerCase()));
      } else if (filter.filterType === COLUMN_FILTER_TYPE.date) {
        filteredRows = filteredRows
          .filter((row) => isSameDay(new Date(row[filter.columnId]), filter.filterValue));
      } else if (filter.filterType === COLUMN_FILTER_TYPE.multiSelect) {
        filteredRows = filteredRows
          .filter((row) => filter.filterValue.includes(row[filter.columnId]));
      }
    });

    return filteredRows;
  };

  const filteredRows = applyColumnFilters(rows);
  const filteredAndSortedRows = stableSort(filteredRows, getComparator(order, orderBy));
  const visibleRows = filteredAndSortedRows.slice(
    (page - 1) * rowsPerPage,
    (page - 1) * rowsPerPage + rowsPerPage,
  );

  useEffect(() => {
    setPage(1);
  }, [rows, columnFilters]);

  const renderTableRow = () => {
    if (variant === TABLE_VARIANT.expanded) {
      return visibleRows.map((row) => (
        <ExpandedRow
          key={row.id}
          row={row}
          columns={columns}
          renderExpandedSection={renderExpandedSection}
          tableMetaData={tableMetaData}
        />
      ));
    }

    return visibleRows.map((row, index) => {
      const key = `${row.id}-${index}`;
      return (
        <TableRow key={`${key}-table-row`}>
          <TableRowCells
            key={`${key}-table-cells`}
            row={row}
            columns={columns}
            onActionItemClicked={onActionItemClicked}
            tableMetaData={tableMetaData}
          />
        </TableRow>
      );
    });
  };

  const renderTableBody = () => {
    if (isLoading) {
      return (
        <TableLoadingIndicator
          rowHeight={rowsPerPage * rowHeight}
          columnSpan={COLUMN_SPAN}
        />
      );
    }
    if (visibleRows.length === 0) {
      return (
        <TextTableOverlay
          text={emptyText}
          rowHeight={rowsPerPage * rowHeight}
          columnSpan={COLUMN_SPAN}
        />
      );
    }

    return (
      <>
        {renderTableRow()}
        {emptyRows > 0 && (
          <TableRow style={{ height: rowHeight * emptyRows }}>
            <TableCell colSpan={COLUMN_SPAN} />
          </TableRow>
        )}
      </>
    );
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <SortableTableHead
          columns={columns}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          leftHeaderPadding={variant === TABLE_VARIANT.expanded ? '125px' : null}
        />
        {!hideFilterRow && (
          <TableFilterRow
            columns={columns}
            isExpandedTable={variant === TABLE_VARIANT.expanded}
            onFilterChanged={onColumnFilterChanged}
          />
        )}
        <TableBody>
          {renderTableBody()}
        </TableBody>
        {!hidePaginationRow && (
          <TablePaginationFooter
            columnSpan={COLUMN_SPAN}
            resultSize={filteredAndSortedRows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            handleChangePage={handleChangePage}
          />
        )}
      </Table>
    </TableContainer>
  );
};

MaterialTable.defaultProps = {
  isLoading: false,
  initialOrder: '',
  initialOrderBy: '',
  variant: TABLE_VARIANT.standard,
  rowHeight: DEFAULT_ROW_HEIGHT,
  rowsPerPage: DEFAULT_ROWS_PER_PAGE,
  renderExpandedSection: null,
  onActionItemClicked: null,
  hideFilterRow: false,
  hidePaginationRow: false,
  emptyText: 'No results found!',
  tableMetaData: {},
};

MaterialTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.any).isRequired,
  columns: PropTypes.arrayOf(PropTypes.any).isRequired,
  isLoading: PropTypes.bool,
  initialOrder: PropTypes.string,
  initialOrderBy: PropTypes.string,
  variant: PropTypes.string,
  rowHeight: PropTypes.number,
  rowsPerPage: PropTypes.number,
  renderExpandedSection: PropTypes.func,
  onActionItemClicked: PropTypes.func,
  hideFilterRow: PropTypes.bool,
  hidePaginationRow: PropTypes.bool,
  emptyText: PropTypes.string,
  tableMetaData: PropTypes.any,
};

export default MaterialTable;
