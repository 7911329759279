import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const LinkedAddress = ({ address }) => {
  if (address) {
    return (
      <Typography
        align="left"
        color="primary"
        component="a"
        href={`https://www.google.com/maps/place/${address}`}
        target="_blank"
        rel="noreferrer noopener"
      >
        {address}
      </Typography>
    );
  }

  return <Typography>Unknown Address</Typography>;
};

LinkedAddress.defaultProps = {
  address: '',
};

LinkedAddress.propTypes = {
  address: PropTypes.string,
};

export default LinkedAddress;
