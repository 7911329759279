import React from 'react';
import { Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import AboutHeader from '../../assets/imgs/aboutHeader.png';
import { DEFAULT_PROVIDER_SCHEMA_VERSION } from '../../api/provider/providerApi';
import LinkButton from '../../common/components/LinkButton';
import { useGetStatusQuery } from '../../api/status/statusApi';
import LoadingIndicator from '../../common/components/LoadingIndicator/LoadingIndicator';

const Section = ({ title, children }) => (
  <Stack>
    <Typography align="left" gutterBottom sx={{ fontWeight: 'bold' }}>
      {title}
    </Typography>
    <Typography align="left">
      {children}
    </Typography>
  </Stack>
);

Section.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const About = () => {
  const { data: status, isLoading } = useGetStatusQuery();

  if (isLoading) return <LoadingIndicator />;

  return (
    <Stack
      alignItems="start"
      spacing={3}
      sx={{ width: 600 }}
    >
      <img src={AboutHeader} alt="About Header" />
      <Section title="Version">
        {process.env.REACT_APP_VERSION}
      </Section>
      <Section title="API Version">
        {status?.version ?? 'N/A'}
      </Section>
      <Section title="Provider Schema Version">
        {DEFAULT_PROVIDER_SCHEMA_VERSION}
      </Section>
      <Section title="Contact Us">
        For questions on the PercayAI suite please contact
        <Typography
          component="a"
          href="mailto:support@percayai.com"
          sx={{ marginLeft: 0.5 }}
          display="inline"
        >
          support@percayai.com
        </Typography>
        .
      </Section>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="center"
        width="100%"
        sx={{ pt: 2 }}
      >
        <LinkButton title="PercayAI Home" href="https://www.percayai.com/" />
        <LinkButton
          title="FWA Help Center"
          href="https://percayai.atlassian.net/wiki/spaces/FRAUD/pages/2118877185/PercayAI+Training+FWA+DRAFT"
        />
      </Stack>
      <Typography width="100%">&copy; 2023 PercayAI. All rights reserved.</Typography>
    </Stack>
  );
};

export default About;
