import React from 'react';
import './loading-indicator.scss';

const LoadingIndicator = () => (
  <div className="sk-cube-grid with-logo">
    <div className="sk-cube sk-cube1" />
    <div className="sk-cube sk-cube2" />
    <div className="sk-cube sk-cube3" />
    <div className="sk-cube sk-cube4" />
    <div className="sk-cube sk-cube5" />
    <div className="sk-cube sk-cube6" />
    <div className="sk-cube sk-cube7" />
    <div className="sk-cube sk-cube8" />
    <div className="sk-cube sk-cube9" />
  </div>
);

export default LoadingIndicator;
