import React from 'react';
import PropTypes from 'prop-types';
import MaterialTable from '../../../common/components/MaterialTable/MaterialTable';
import { formatCurrency } from '../../../common/utils/formatting';

const PAYMENT_SUMMARY_COLUMNS = [
  {
    id: 'id',
    label: 'Provider NPI',
    width: '14%',
    renderCell: (row) => {
      const { id } = row;
      return <div>{id}</div>;
    },
  },
  {
    id: 'numberOfDistinctRecipients',
    label: 'Number of Distinct Recipients',
    width: '14%',
    renderCell: (row) => {
      const { numberOfDistinctRecipients } = row;
      return <div>{numberOfDistinctRecipients}</div>;
    },
  },
  {
    id: 'totalClaims',
    label: 'Total Claims',
    width: '14%',
    renderCell: (row) => {
      const { totalClaims } = row;
      return <div>{totalClaims}</div>;
    },
  },
  {
    id: 'totalClaimLines',
    label: 'Total Claim Lines',
    width: '14%',
    renderCell: (row) => {
      const { totalClaimLines } = row;
      return <div>{totalClaimLines}</div>;
    },
  },
  {
    id: 'avgClaimLinesPerPatient',
    label: 'Average Number of Claim Lines Per Patient',
    width: '14%',
    renderCell: (row) => {
      const { avgClaimLinesPerPatient } = row;
      return <div>{avgClaimLinesPerPatient}</div>;
    },
  },
  {
    id: 'totalBilled',
    label: 'Total Billed',
    width: '14%',
    renderCell: (row) => {
      const { totalBilled } = row;
      return <div>{formatCurrency(totalBilled)}</div>;
    },
  },
  {
    id: 'totalPaid',
    label: 'Total Paid',
    width: '14%',
    renderCell: (row) => {
      const { totalPaid } = row;
      return <div>{formatCurrency(totalPaid)}</div>;
    },
  },
];

const PaymentSummaryTable = ({ paymentSummary }) => (
  <MaterialTable
    rows={paymentSummary}
    columns={PAYMENT_SUMMARY_COLUMNS}
    rowsPerPage={paymentSummary?.length || 1}
    hideFilterRow
    hidePaginationRow
    emptyText="No Data Available!"
  />
);

PaymentSummaryTable.defaultProps = {
  paymentSummary: [],
};

PaymentSummaryTable.propTypes = {
  paymentSummary: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    numberOfDistinctRecipients: PropTypes.number,
    totalClaims: PropTypes.number,
    totalClaimLines: PropTypes.number,
    avgClaimLinesPerPatient: PropTypes.number,
    totalBilled: PropTypes.number,
    totalPaid: PropTypes.number,
  })),
};

export default PaymentSummaryTable;
