import { formatZipCode, toProperCase } from '../../common/utils/formatting';

const formatFullName = (name, medicalCredentialText) => {
  if (!name) return 'Unknown Provider';

  const { givenName, additionalName, familyName } = name;
  const givenNameFormatted = toProperCase(givenName);
  const additionalNameFormatted = toProperCase(additionalName);
  const familyNameFormatted = toProperCase(familyName);

  const fullName = `${givenNameFormatted}${additionalNameFormatted ? ` ${additionalNameFormatted}` : ''} ${familyNameFormatted}`.trim();
  const fullNameWithCredentials = `${fullName}${medicalCredentialText ? `, ${medicalCredentialText}` : ''}`;

  return fullNameWithCredentials || 'Unknown Provider';
};

const formatAddress = (address) => {
  if (!address) return null;

  const {
    streetAddressLine1,
    streetAddressLine2,
    addressLocality,
    addressRegion,
    postalCode,
  } = address;
  const formattedZip = formatZipCode(postalCode);

  if (!streetAddressLine1 && !streetAddressLine2) return null;

  return `${streetAddressLine1}${streetAddressLine2 ? ` ${streetAddressLine2}` : ''} ${addressLocality}, ${addressRegion} ${formattedZip}`;
};

const formatSpecialties = (specialties) => {
  if (!specialties) return 'Unknown Specialties';
  return specialties[0].trim().split(';').filter((val) => val).join(', ') || 'Unknown Specialties';
};

const formatLicenses = (licenses) => {
  if (!licenses) return [];

  const formattedLicenses = [];
  licenses.forEach((license) => {
    if (!formattedLicenses.some((l) => l.number === license.number)) {
      formattedLicenses.push(license);
    }
  });

  return formattedLicenses;
};

const formatPaymentSummary = (npi, paymentSummary) => {
  if (!paymentSummary) return null;

  return paymentSummary
    .map((item) => ({ id: npi, ...item }));
};

const formatProcedureSummary = (procedureSummary) => {
  if (!procedureSummary) return null;

  return procedureSummary.map((item) => {
    const { procedureCode: id, ...rest } = item;
    return { id, ...rest };
  });
};

const formatDiagnosisSummary = (diagnosisSummary) => {
  if (!diagnosisSummary) return null;

  return diagnosisSummary.map((item) => {
    const { diagnosisCode: id, ...rest } = item;
    return { id, ...rest };
  });
};

const formatPrescriptionSummary = (prescriptionSummary) => {
  if (!prescriptionSummary) return null;

  return prescriptionSummary.map((item) => {
    const { ndcCode: id, ...rest } = item;
    return { id, ...rest };
  });
};

const formatPlaceOfService = (placeOfService) => {
  if (!placeOfService) return null;

  return placeOfService.map((item) => {
    const { placeOfServiceCode: id, ...rest } = item;
    return { id, ...rest };
  });
};

const toProviderInfo = (data) => {
  const {
    npi,
    basicInfo,
    paymentSummary,
    procedureSummary,
    diagnosisSummary,
    prescriptionSummary,
    placeOfServiceSummary,
  } = data;

  return {
    name: formatFullName(basicInfo?.name, basicInfo?.medicalCredentialText),
    practiceAddress: formatAddress(basicInfo?.practiceLocationAddress),
    mailingAddress: formatAddress(basicInfo?.mailingAddress),
    specialties: formatSpecialties(basicInfo?.medicalSpecialties),
    licenses: formatLicenses(basicInfo?.medicalLicenses),
    paymentSummary: formatPaymentSummary(npi, paymentSummary?.records),
    paymentSummaryTotalRecords: paymentSummary?.totalRecords,
    procedureSummary: formatProcedureSummary(procedureSummary?.records),
    procedureSummaryTotalRecords: procedureSummary?.totalRecords,
    diagnosisSummary: formatDiagnosisSummary(diagnosisSummary?.records),
    diagnosisSummaryTotalRecords: diagnosisSummary?.totalRecords,
    prescriptionSummary: formatPrescriptionSummary(prescriptionSummary?.records),
    prescriptionSummaryTotalRecords: prescriptionSummary?.totalRecords,
    placeOfService: formatPlaceOfService(placeOfServiceSummary?.records),
    placeOfServiceSummaryTotalRecords: placeOfServiceSummary?.totalRecords,
  };
};

export default toProviderInfo;
