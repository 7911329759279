import React from 'react';
import PropTypes from 'prop-types';
import MaterialTable from '../../../common/components/MaterialTable/MaterialTable';
import {
  COLUMN_FILTER_TYPE,
  SORT_ORDER,
} from '../../../common/components/MaterialTable/tableConstants';
import { formatCurrency } from '../../../common/utils/formatting';

export const DIAGNOSIS_SUMMARY_COLUMNS = [
  {
    id: 'id',
    label: 'Diagnosis Code',
    width: '11%',
    isFilterEnabled: true,
    filterType: COLUMN_FILTER_TYPE.default,
    renderCell: (row) => {
      const { id } = row;
      return <div>{id}</div>;
    },
  },
  {
    id: 'diagnosisCodeDescription',
    label: 'Diagnosis Code Description',
    width: '11%',
    isFilterEnabled: true,
    filterType: COLUMN_FILTER_TYPE.default,
    renderCell: (row) => {
      const { diagnosisCodeDescription } = row;
      return <div>{diagnosisCodeDescription}</div>;
    },
  },
  {
    id: 'totalNumberDistinctRecipients',
    label: 'Total Number of Distinct Recipients',
    width: '11%',
    isFilterEnabled: true,
    filterType: COLUMN_FILTER_TYPE.default,
    renderCell: (row) => {
      const { totalNumberDistinctRecipients } = row;
      return <div>{totalNumberDistinctRecipients}</div>;
    },
  },
  {
    id: 'totalNumberClaims',
    label: 'Total Number of Claims',
    width: '11%',
    isFilterEnabled: true,
    filterType: COLUMN_FILTER_TYPE.default,
    renderCell: (row) => {
      const { totalNumberClaims } = row;
      return <div>{totalNumberClaims}</div>;
    },
  },
  {
    id: 'totalNumberClaimLines',
    label: 'Total Number of Claim Lines',
    width: '11%',
    isFilterEnabled: true,
    filterType: COLUMN_FILTER_TYPE.default,
    renderCell: (row) => {
      const { totalNumberClaimLines } = row;
      return <div>{totalNumberClaimLines}</div>;
    },
  },
  {
    id: 'avgNumberClaimLinesPerPatient',
    label: 'Avg. Number of Claim Lines Per Patient',
    width: '11%',
    isFilterEnabled: true,
    filterType: COLUMN_FILTER_TYPE.default,
    renderCell: (row) => {
      const { avgNumberClaimLinesPerPatient } = row;
      return <div>{avgNumberClaimLinesPerPatient}</div>;
    },
  },
  {
    id: 'totalBilled',
    label: 'Total Billed',
    width: '11%',
    isFilterEnabled: true,
    filterType: COLUMN_FILTER_TYPE.default,
    renderCell: (row) => {
      const { totalBilled } = row;
      return <div>{formatCurrency(totalBilled)}</div>;
    },
  },
  {
    id: 'totalPaid',
    label: 'Total Paid',
    width: '11%',
    isFilterEnabled: true,
    filterType: COLUMN_FILTER_TYPE.default,
    renderCell: (row) => {
      const { totalPaid } = row;
      return <div>{formatCurrency(totalPaid)}</div>;
    },
  },
  {
    id: 'avgPaidPerPatient',
    label: 'Avg. Paid Per Patient',
    width: '11%',
    isFilterEnabled: true,
    filterType: COLUMN_FILTER_TYPE.default,
    renderCell: (row) => {
      const { avgPaidPerPatient } = row;
      return <div>{formatCurrency(avgPaidPerPatient)}</div>;
    },
  },
];

const DiagnosisSummaryTable = ({ diagnosisSummary }) => (
  <MaterialTable
    rows={diagnosisSummary}
    columns={DIAGNOSIS_SUMMARY_COLUMNS}
    initialOrder={SORT_ORDER.descending}
    initialOrderBy="totalPaid"
    rowsPerPage={diagnosisSummary?.length || 1}
    hideFilterRow
    hidePaginationRow
    emptyText="No Data Available!"
  />
);

DiagnosisSummaryTable.defaultProps = {
  diagnosisSummary: [],
};

DiagnosisSummaryTable.propTypes = {
  diagnosisSummary: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    diagnosisCodeDescription: PropTypes.string,
    totalNumberDistinctRecipients: PropTypes.number,
    totalNumberClaims: PropTypes.number,
    totalNumberClaimsLines: PropTypes.number,
    avgNumberClaimLinesPerPatient: PropTypes.number,
    totalBilled: PropTypes.number,
    totalPaid: PropTypes.number,
    avgPaidPerPatient: PropTypes.number,
  })),
};

export default DiagnosisSummaryTable;
