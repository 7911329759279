/* eslint-disable react/forbid-prop-types */
import { NavLink, useMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import variables from './SideNav.scss';

const NavMenuItem = ({ route }) => {
  const isActive = useMatch(`${route.path}/*`);

  return (
    <NavLink className="menu__item" end to={route.path}>
      <div className="link-container">
        <route.icon htmlColor={isActive ? variables.activeColor : variables.inactiveColor} />
        <span className={`menu__item__caption ${isActive && 'menu__item__caption__selected'}`}>{route.label}</span>
      </div>
    </NavLink>
  );
};

NavMenuItem.propTypes = {
  route: PropTypes.shape({
    label: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    icon: PropTypes.object.isRequired,
  }).isRequired,
};

export default NavMenuItem;
