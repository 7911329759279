import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';

const LinkButton = ({ title, href }) => (
  <Button
    variant="contained"
    component="a"
    href={href}
    target="_blank"
    rel="noreferrer noopener"
  >
    {title}
  </Button>
);

LinkButton.propTypes = {
  title: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
};

export default LinkButton;
