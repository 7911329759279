import React from 'react';
import LockIcon from '@mui/icons-material/Lock';
import { Button, Stack, Typography } from '@mui/material';
import appColors from 'assets/colors/appColors';
import { useAuth } from 'oidc-react';

const UnauthorizedMessage = () => {
  const { signOutRedirect } = useAuth();

  return (
    <Stack
      spacing={3}
      alignItems="center"
      sx={{ marginTop: 24 }}
    >
      <LockIcon sx={{ fontSize: 150, color: appColors.darkBlue }} />
      <Stack spacing={0.5} alignItems="center">
        <Typography sx={{ fontSize: 28 }}>
          Looks like you don&apos;t have access to this.
        </Typography>
        <Typography align="left">
          Please reach out to
          <Typography
            component="a"
            href="mailto:support@percayai.com"
            sx={{ marginLeft: 0.5 }}
            display="inline"
          >
            support@percayai.com
          </Typography>
          {' '}
          for assistance.
        </Typography>
      </Stack>
      <Button variant="contained" onClick={signOutRedirect}>Log out</Button>
    </Stack>
  );
};

export default UnauthorizedMessage;
